import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors } from 'styles/colors'
// import CallbackIcon from 'assets/icons/callback.svg'
import { displayWidth } from 'styles/width'
import { Modal } from './Modal'
import { Form, FormTracking, IChildrenProps } from 'components/form/Form'
import { PhoneInput } from 'components/form/PhoneInput'
import { useStaticQuery, graphql } from 'gatsby'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { Title } from 'components/TitleComponent'
import { useFormHandler } from 'hooks/useFormHandler'
import { PhoneSvgAnimated } from './PhoneSvgAnimated'
import { contactInformation } from './contactInformation'
import { sendConversion, sendEvent } from 'tracking'
import { sendForm } from './form/api'
import { EmailInput } from 'components/form/EmailInput'
import { MessageInput } from 'components/form/MessageInput'

const CallbackButtonWrapperMobile = styled.button<{
    open?: boolean
    hideCallback: number
}>`
    position: fixed;
    display: none;
    bottom: 80px;
    left: 18px;
    width: 64px;
    height: 64px;
    ${({ open }) =>
        open === true
            ? `background-color: rgba(213, 213, 213, 0.85596)`
            : `background-color: ${colors.dark}`};
    ${({ hideCallback }) =>
        (hideCallback < 550 || hideCallback === 0) && `display:none`};
    border: 1px solid ${colors.dark};
    border-radius: 50%;
    z-index: 13;
    :after,
    :before {
        pointer-events: none;
        content: '';
        display: block;
        position: absolute;
        border: 1px solid ${colors.dark};
        border-radius: 50%;
        bottom: -10px;
        top: -10px;
        right: -10px;
        left: -10px;
        animation: ${(props) =>
            props.open ? 'none' : 'pulse 2s linear infinite;'};
        opacity: 0;
        background-color: rgba(255, 255, 255, 0.07);
    }
    :after {
        animation-delay: 0.5s;
    }
    @keyframes pulse {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: scale(1.2);
            opacity: 0;
        }
    }
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
        left: 8px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        left: calc((50vw - ${displayWidth.desktop} / 2) + 8px);
    }
    :hover {
        cursor: pointer;
    }
`
// const CallbackButtonWrapperDesktop = styled(CallbackButtonWrapperMobile)`
//     display: none;
//     @media (min-width: ${displayWidth.tablet}) {
//         display: block;
//         left: 8px;
//         ${({ hideCallback }) =>
//             (hideCallback < 170 || hideCallback === 0) && `display:none`};
//     }
//     @media (min-width: ${displayWidth.desktop}) {
//         left: calc((50vw - ${displayWidth.desktop} / 2) + 8px);
//         ${({ hideCallback }) =>
//             (hideCallback < 200 || hideCallback === 0) && `display:none`};
//     }
// `

// const callbackButtonIcon = css`
//     fill: ${colors.white};
//     width: 32px;
//     height: 64px;
//     cursor: pointer;
//     pointer-events: auto;
// `
// const CallbackIconStyled = styled(CallbackIcon)`
//     ${callbackButtonIcon};
// `
const PhoneSvgAnimatedStyled = styled(PhoneSvgAnimated)`
    pointer-events: auto;
    fill: ${colors.white};
    width: 40%;
    height: 40%;
    left: 30%;
    top: 30%;
`

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media (orientation: landscape) {
        padding: 10px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        align-items: flex-start;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 30px 70px 0 30px;
        justify-content: space-around;
    }
`
const TitleStyled = styled(Title)`
    margin: 50px 0;
    @media (orientation: landscape) {
        margin: 16px 0;
    }
`
const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${displayWidth.desktop}) {
        width: 100%;
    }
`

export const CallbackButton = ({
    tracking,
    hideCallback,
}: {
    tracking: FormTracking
    hideCallback: number
}) => {
    const [isModalOpen, setModalIsOpen] = useState(false)

    // TODO: move to Form
    const {
        handleSubmitStatus,
        handleFormSendStart,
        formSendStatus,
    } = useFormHandler()

    const { t, i18n } = useTranslation()

    const data = useStaticQuery(graphql`
        query {
            allCallbackButtonYaml {
                edges {
                    node {
                        contactUs
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)
    const addressData = getDataByLanguage(
        data.allCallbackButtonYaml,
        i18n.language
    )
    const { contactUs } = addressData

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeHandler={() => setModalIsOpen(false)}
            >
                <Wrapper>
                    <TitleStyled>{contactUs}</TitleStyled>
                    <Form
                        formName={'Question Form'}
                        buttonText={t('send')}
                        onFormSubmit={handleSubmitStatus}
                        onFormSendStart={handleFormSendStart}
                        formSendStatus={formSendStatus}
                        closeHandler={setModalIsOpen}
                        {...tracking}
                    >
                        {({ register, errors }: IChildrenProps) => (
                            <InputBlock>
                                <PhoneInput
                                    ref={register({
                                        minLength: 18,
                                        required: true,
                                    })}
                                    err={errors.phone}
                                />
                                <MessageInput
                                    ref={register}
                                    err={errors.message}
                                />
                                <EmailInput ref={register} err={errors.email} />
                            </InputBlock>
                        )}
                    </Form>
                </Wrapper>
            </Modal>
            <CallbackButtonWrapperMobile
                hideCallback={hideCallback}
                aria-label="Callback Button"
            >
                <a
                    href={`tel:${contactInformation.primaryPhone}`}
                    onClick={() => {
                        sendForm(`CallBack button Phone`, {})
                        sendConversion('Phone')
                        sendEvent('Click', {
                            eventCategory: 'PhoneClickCircle',
                            type: 'CallBack button',
                        })
                    }}
                    id="writeUsButton"
                >
                    <PhoneSvgAnimatedStyled />
                </a>
            </CallbackButtonWrapperMobile>

            {/* <CallbackButtonWrapperDesktop
                hideCallback={hideCallback}
                aria-label="Callback Button"
                onClick={() => {
                    setModalIsOpen(true)
                }}
                id="writeUsButton"
            >
                <CallbackIconStyled />
            </CallbackButtonWrapperDesktop> */}
        </>
    )
}
