import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Header } from 'blocks/Header/Header'
import { Footer } from 'blocks/Footer'
import { usePagePath } from 'hooks/usePagePath'
import { displayWidth } from 'styles/width'
import { CallbackButton } from 'components/CallbackButton'
import { contactInformation } from 'components/contactInformation'
import { useStaticQuery, graphql } from 'gatsby'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { sendEvent, gtag } from 'tracking'
import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'

const LayoutWraper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    touch-action: none;
`
const BlocksWrapper = styled.div`
    position: absolute;
    width: 100%;
    top: 65px;
    bottom: 60px;
    overflow-y: auto;
    box-sizing: content-box;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    @media (min-width: ${displayWidth.tablet}) {
        top: 80px;
        bottom: 0px;
    }
`

export const Layout = ({
    children,
    noFooter = true,
    hideCallbackProp,
    id,
}: {
    children: React.ReactNode
    noFooter?: boolean
    hideCallbackProp?: boolean
    id?: string
}) => {
    const { i18n } = useTranslation()
    const { getPagePath } = usePagePath()
    const [hideCallback, setHideCallback] = useState(1000)
    const data = useStaticQuery(graphql`
        query {
            allAddressYaml {
                edges {
                    node {
                        companyName
                        street
                        city
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
            allReviewsYaml {
                edges {
                    node {
                        rating
                        bestRating
                        worstRating
                        reviewsArr {
                            text
                            name
                        }
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)

    const commonData = data.allReviewsYaml.edges.find(
        (elem: { node: { parent: { name: string } } }) => {
            return elem.node.parent.name === 'reviews'
        }
    ).node

    const { rating, bestRating, worstRating, reviewsArr } = commonData
    const addressData = getDataByLanguage(data.allAddressYaml, i18n.language)

    const { companyName, street, city } = addressData

    const reviewData = reviewsArr.map(
        (item: { name: string; text: string }) => {
            return {
                '@type': 'Review',
                author: `${item.name}`,
                name: `${item.name}`,
                reviewBody: `${item.text.replace(/<[^>]*>/g, '')}`,
                reviewRating: {
                    '@type': 'Rating',
                    bestRating: `${bestRating}`,
                    ratingValue: `${rating}`,
                    worstRating: `${worstRating}`,
                },
            }
        }
    )
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])

    const onScroll = () => {
        const block = document.getElementById(id || 'blockWrapper')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight

        setHideCallback(bodyHeight - (scrollPosition + windowHeight))
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScroll()
        }, 700)
    }

    const metaPinterest = () => {
        switch (i18n.language) {
            case 'ru':
                return (
                    <meta
                        name="p:domain_verify"
                        content="45f1db8144d3ab74a0527dc6f752578b"
                    />
                )
            case 'uk':
                return (
                    <meta
                        name="p:domain_verify"
                        content="7435f8834bebe90a23e2e07bf750f725"
                    />
                )
            case 'en':
                return (
                    <meta
                        name="p:domain_verify"
                        content="139e6e5d9f93cb0da7b7f722dcbb7ace"
                    />
                )
            default:
                return null
        }
    }

    return (
        <LayoutWraper>
            <Helmet>
                <meta
                    name="p:domain_verify"
                    content="36efde96fc1940866e6b30456beeef0e"
                />
                {metaPinterest()}
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org/",
                            "@type": "Organization",
                            "url": "https://clearline.com.ua/",
                            "logo": "https://clearline.com.ua/email-logo.jpg",
                            "geo": {
                                    "@type": "GeoCoordinates",
                                    "latitude": 50.440771,
                                    "longitude": 30.507301
                            },
                            "image": "https://clearline.com.ua/email-logo.jpg",
                            "name": "${companyName}",
                            "telephone": "${contactInformation.primaryPhone}",
                            "address" :{
                            "@type": "PostalAddress",
                            "streetAddress": "${street}",
                            "addressLocality": "${city}"
                        },
                        "reviewRating": {
                            "@type": "Rating",
                            "bestRating": "${bestRating}",
                            "ratingValue": "${rating}",
                            "worstRating": "${worstRating}"
                        },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "${rating}",
                            "reviewCount": "70"
                        },
                          "review": ${JSON.stringify(reviewData)}
                        }`}
                </script>
            </Helmet>
            <Header />
            <MobileMenuPosadka />

            <BlocksWrapper id={id || 'blockWrapper'} onScroll={onScroll}>
                {children}
                {noFooter && <Footer />}
            </BlocksWrapper>
            <CallbackButton
                hideCallback={hideCallbackProp ? 0 : hideCallback}
                tracking={{
                    conversionType: 'CallBackButtonForm',
                    eventCategory: 'Callback',
                }}
            />
            <PosadkaLinkBlock />
        </LayoutWraper>
    )
}
